export const FAMILY_LOAD_CHILDREN_INIT = 'FAMILY_LOAD_CHILDREN_INIT';
export const FAMILY_LOAD_CHILDREN_BEGIN = 'FAMILY_LOAD_CHILDREN_BEGIN';
export const FAMILY_LOAD_CHILDREN_SUCCESS = 'FAMILY_LOAD_CHILDREN_SUCCESS';
export const FAMILY_LOAD_CHILDREN_FAILURE = 'FAMILY_LOAD_CHILDREN_FAILURE';
export const FAMILY_LOAD_CHILDREN_DISMISS_ERROR = 'FAMILY_LOAD_CHILDREN_DISMISS_ERROR';
export const FAMILY_TOGGLE = 'FAMILY_TOGGLE';
export const FAMILY_SELECT = 'FAMILY_SELECT';
export const FAMILY_LOAD_ONE_BEGIN = 'FAMILY_LOAD_ONE_BEGIN';
export const FAMILY_LOAD_ONE_SUCCESS = 'FAMILY_LOAD_ONE_SUCCESS';
export const FAMILY_LOAD_ONE_FAILURE = 'FAMILY_LOAD_ONE_FAILURE';
export const FAMILY_LOAD_ONE_DISMISS_ERROR = 'FAMILY_LOAD_ONE_DISMISS_ERROR';
export const FAMILY_CREATE_ONE_BEGIN = 'FAMILY_CREATE_ONE_BEGIN';
export const FAMILY_CREATE_ONE_SUCCESS = 'FAMILY_CREATE_ONE_SUCCESS';
export const FAMILY_CREATE_ONE_FAILURE = 'FAMILY_CREATE_ONE_FAILURE';
export const FAMILY_CREATE_ONE_DISMISS_ERROR = 'FAMILY_CREATE_ONE_DISMISS_ERROR';
export const FAMILY_DEL_ONE_BEGIN = 'FAMILY_DEL_ONE_BEGIN';
export const FAMILY_DEL_ONE_SUCCESS = 'FAMILY_DEL_ONE_SUCCESS';
export const FAMILY_DEL_ONE_FAILURE = 'FAMILY_DEL_ONE_FAILURE';
export const FAMILY_DEL_ONE_DISMISS_ERROR = 'FAMILY_DEL_ONE_DISMISS_ERROR';
export const FAMILY_UPDATE_ONE_UPDATE = 'FAMILY_UPDATE_ONE_UPDATE';
export const FAMILY_UPDATE_ONE_BEGIN = 'FAMILY_UPDATE_ONE_BEGIN';
export const FAMILY_UPDATE_ONE_SUCCESS = 'FAMILY_UPDATE_ONE_SUCCESS';
export const FAMILY_UPDATE_ONE_FAILURE = 'FAMILY_UPDATE_ONE_FAILURE';
export const FAMILY_UPDATE_ONE_DISMISS_ERROR = 'FAMILY_UPDATE_ONE_DISMISS_ERROR';
