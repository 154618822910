export const CONTRACT_LOAD_MORE_INIT = 'CONTRACT_LOAD_MORE_INIT';
export const CONTRACT_LOAD_MORE_BEGIN = 'CONTRACT_LOAD_MORE_BEGIN';
export const CONTRACT_LOAD_MORE_SUCCESS = 'CONTRACT_LOAD_MORE_SUCCESS';
export const CONTRACT_LOAD_MORE_FAILURE = 'CONTRACT_LOAD_MORE_FAILURE';
export const CONTRACT_LOAD_MORE_DISMISS_ERROR = 'CONTRACT_LOAD_MORE_DISMISS_ERROR';
export const CONTRACT_CREATE_ONE_BEGIN = 'CONTRACT_CREATE_ONE_BEGIN';
export const CONTRACT_CREATE_ONE_SUCCESS = 'CONTRACT_CREATE_ONE_SUCCESS';
export const CONTRACT_CREATE_ONE_FAILURE = 'CONTRACT_CREATE_ONE_FAILURE';
export const CONTRACT_CREATE_ONE_DISMISS_ERROR = 'CONTRACT_CREATE_ONE_DISMISS_ERROR';
export const CONTRACT_LOAD_ONE_BEGIN = 'CONTRACT_LOAD_ONE_BEGIN';
export const CONTRACT_LOAD_ONE_SUCCESS = 'CONTRACT_LOAD_ONE_SUCCESS';
export const CONTRACT_LOAD_ONE_FAILURE = 'CONTRACT_LOAD_ONE_FAILURE';
export const CONTRACT_LOAD_ONE_DISMISS_ERROR = 'CONTRACT_LOAD_ONE_DISMISS_ERROR';
export const CONTRACT_UPDATE_ONE_BEGIN = 'CONTRACT_UPDATE_ONE_BEGIN';
export const CONTRACT_UPDATE_ONE_SUCCESS = 'CONTRACT_UPDATE_ONE_SUCCESS';
export const CONTRACT_UPDATE_ONE_FAILURE = 'CONTRACT_UPDATE_ONE_FAILURE';
export const CONTRACT_UPDATE_ONE_DISMISS_ERROR = 'CONTRACT_UPDATE_ONE_DISMISS_ERROR';
export const CONTRACT_UPDATE_ONE_UPDATE = 'CONTRACT_UPDATE_ONE_UPDATE';
export const CONTRACT_INIT_FILTERS = 'CONTRACT_INIT_FILTERS';
export const CONTRACT_INIT_SORT = 'CONTRACT_INIT_SORT';
export const CONTRACT_SET_FILTERS = 'CONTRACT_SET_FILTERS';
export const CONTRACT_SET_SORT = 'CONTRACT_SET_SORT';
export const CONTRACT_DEL_ONE_BEGIN = 'CONTRACT_DEL_ONE_BEGIN';
export const CONTRACT_DEL_ONE_SUCCESS = 'CONTRACT_DEL_ONE_SUCCESS';
export const CONTRACT_DEL_ONE_FAILURE = 'CONTRACT_DEL_ONE_FAILURE';
export const CONTRACT_DEL_ONE_DISMISS_ERROR = 'CONTRACT_DEL_ONE_DISMISS_ERROR';
export const CONTRACT_UPLOAD_CONTRACT_MEDIA_BEGIN = 'CONTRACT_UPLOAD_CONTRACT_MEDIA_BEGIN';
export const CONTRACT_UPLOAD_CONTRACT_MEDIA_SUCCESS = 'CONTRACT_UPLOAD_CONTRACT_MEDIA_SUCCESS';
export const CONTRACT_UPLOAD_CONTRACT_MEDIA_FAILURE = 'CONTRACT_UPLOAD_CONTRACT_MEDIA_FAILURE';
export const CONTRACT_UPLOAD_CONTRACT_MEDIA_DISMISS_ERROR = 'CONTRACT_UPLOAD_CONTRACT_MEDIA_DISMISS_ERROR';
export const CONTRACT_DEL_CONTRACT_MEDIA_BEGIN = 'CONTRACT_DEL_CONTRACT_MEDIA_BEGIN';
export const CONTRACT_DEL_CONTRACT_MEDIA_SUCCESS = 'CONTRACT_DEL_CONTRACT_MEDIA_SUCCESS';
export const CONTRACT_DEL_CONTRACT_MEDIA_FAILURE = 'CONTRACT_DEL_CONTRACT_MEDIA_FAILURE';
export const CONTRACT_DEL_CONTRACT_MEDIA_DISMISS_ERROR = 'CONTRACT_DEL_CONTRACT_MEDIA_DISMISS_ERROR';
export const CONTRACT_UPDATE_QUICK_SEARCH = 'CONTRACT_UPDATE_QUICK_SEARCH';
export const CONTRACT_UPDATE_SORT = 'CONTRACT_UPDATE_SORT';
export const CONTRACT_UPDATE_CONTRACT_MEDIA_DESC_BEGIN = 'CONTRACT_UPDATE_CONTRACT_MEDIA_DESC_BEGIN';
export const CONTRACT_UPDATE_CONTRACT_MEDIA_DESC_SUCCESS = 'CONTRACT_UPDATE_CONTRACT_MEDIA_DESC_SUCCESS';
export const CONTRACT_UPDATE_CONTRACT_MEDIA_DESC_FAILURE = 'CONTRACT_UPDATE_CONTRACT_MEDIA_DESC_FAILURE';
export const CONTRACT_UPDATE_CONTRACT_MEDIA_DESC_DISMISS_ERROR = 'CONTRACT_UPDATE_CONTRACT_MEDIA_DESC_DISMISS_ERROR';
