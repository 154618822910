export const ALERT_LOAD_MORE_INIT = 'ALERT_LOAD_MORE_INIT';
export const ALERT_LOAD_MORE_BEGIN = 'ALERT_LOAD_MORE_BEGIN';
export const ALERT_LOAD_MORE_SUCCESS = 'ALERT_LOAD_MORE_SUCCESS';
export const ALERT_LOAD_MORE_FAILURE = 'ALERT_LOAD_MORE_FAILURE';
export const ALERT_LOAD_MORE_DISMISS_ERROR = 'ALERT_LOAD_MORE_DISMISS_ERROR';
export const ALERT_LOAD_PENDINGS_BEGIN = 'ALERT_LOAD_PENDINGS_BEGIN';
export const ALERT_LOAD_PENDINGS_SUCCESS = 'ALERT_LOAD_PENDINGS_SUCCESS';
export const ALERT_LOAD_PENDINGS_FAILURE = 'ALERT_LOAD_PENDINGS_FAILURE';
export const ALERT_LOAD_PENDINGS_DISMISS_ERROR = 'ALERT_LOAD_PENDINGS_DISMISS_ERROR';
export const ALERT_LOAD_ONE_BEGIN = 'ALERT_LOAD_ONE_BEGIN';
export const ALERT_LOAD_ONE_SUCCESS = 'ALERT_LOAD_ONE_SUCCESS';
export const ALERT_LOAD_ONE_FAILURE = 'ALERT_LOAD_ONE_FAILURE';
export const ALERT_LOAD_ONE_DISMISS_ERROR = 'ALERT_LOAD_ONE_DISMISS_ERROR';
export const ALERT_UPDATE_ONE_BEGIN = 'ALERT_UPDATE_ONE_BEGIN';
export const ALERT_UPDATE_ONE_SUCCESS = 'ALERT_UPDATE_ONE_SUCCESS';
export const ALERT_UPDATE_ONE_FAILURE = 'ALERT_UPDATE_ONE_FAILURE';
export const ALERT_UPDATE_ONE_DISMISS_ERROR = 'ALERT_UPDATE_ONE_DISMISS_ERROR';
export const ALERT_UPDATE_ONE_UPDATE = 'ALERT_UPDATE_ONE_UPDATE';
export const ALERT_CREATE_ONE_BEGIN = 'ALERT_CREATE_ONE_BEGIN';
export const ALERT_CREATE_ONE_SUCCESS = 'ALERT_CREATE_ONE_SUCCESS';
export const ALERT_CREATE_ONE_FAILURE = 'ALERT_CREATE_ONE_FAILURE';
export const ALERT_CREATE_ONE_DISMISS_ERROR = 'ALERT_CREATE_ONE_DISMISS_ERROR';
export const ALERT_DEL_ONE_BEGIN = 'ALERT_DEL_ONE_BEGIN';
export const ALERT_DEL_ONE_SUCCESS = 'ALERT_DEL_ONE_SUCCESS';
export const ALERT_DEL_ONE_FAILURE = 'ALERT_DEL_ONE_FAILURE';
export const ALERT_DEL_ONE_DISMISS_ERROR = 'ALERT_DEL_ONE_DISMISS_ERROR';
export const ALERT_INIT_SORT = 'ALERT_INIT_SORT';
export const ALERT_UPDATE_SORT = 'ALERT_UPDATE_SORT';
export const ALERT_INIT_FILTERS = 'ALERT_INIT_FILTERS';
export const ALERT_SET_FILTERS = 'ALERT_SET_FILTERS';
export const ALERT_UPDATE_QUICK_SEARCH = 'ALERT_UPDATE_QUICK_SEARCH';
export const ALERT_SET_SORT = 'ALERT_SET_SORT';
export const ALERT_LOAD_ALERTS_WARNING_BEGIN = 'ALERT_LOAD_ALERTS_WARNING_BEGIN';
export const ALERT_LOAD_ALERTS_WARNING_SUCCESS = 'ALERT_LOAD_ALERTS_WARNING_SUCCESS';
export const ALERT_LOAD_ALERTS_WARNING_FAILURE = 'ALERT_LOAD_ALERTS_WARNING_FAILURE';
export const ALERT_LOAD_ALERTS_WARNING_DISMISS_ERROR = 'ALERT_LOAD_ALERTS_WARNING_DISMISS_ERROR';
export const ALERT_LOAD_ALERTS_DANGER_BEGIN = 'ALERT_LOAD_ALERTS_DANGER_BEGIN';
export const ALERT_LOAD_ALERTS_DANGER_SUCCESS = 'ALERT_LOAD_ALERTS_DANGER_SUCCESS';
export const ALERT_LOAD_ALERTS_DANGER_FAILURE = 'ALERT_LOAD_ALERTS_DANGER_FAILURE';
export const ALERT_LOAD_ALERTS_DANGER_DISMISS_ERROR = 'ALERT_LOAD_ALERTS_DANGER_DISMISS_ERROR';
