export const CAUSE_SICKNESS_LOAD_SICKNESSES_BEGIN = 'CAUSE_SICKNESS_LOAD_SICKNESSES_BEGIN';
export const CAUSE_SICKNESS_LOAD_SICKNESSES_SUCCESS = 'CAUSE_SICKNESS_LOAD_SICKNESSES_SUCCESS';
export const CAUSE_SICKNESS_LOAD_SICKNESSES_FAILURE = 'CAUSE_SICKNESS_LOAD_SICKNESSES_FAILURE';
export const CAUSE_SICKNESS_LOAD_SICKNESSES_DISMISS_ERROR = 'CAUSE_SICKNESS_LOAD_SICKNESSES_DISMISS_ERROR';
export const CAUSE_SICKNESS_LOAD_ONE_BEGIN = 'CAUSE_SICKNESS_LOAD_ONE_BEGIN';
export const CAUSE_SICKNESS_LOAD_ONE_SUCCESS = 'CAUSE_SICKNESS_LOAD_ONE_SUCCESS';
export const CAUSE_SICKNESS_LOAD_ONE_FAILURE = 'CAUSE_SICKNESS_LOAD_ONE_FAILURE';
export const CAUSE_SICKNESS_LOAD_ONE_DISMISS_ERROR = 'CAUSE_SICKNESS_LOAD_ONE_DISMISS_ERROR';
export const CAUSE_SICKNESS_CREATE_ONE_BEGIN = 'CAUSE_SICKNESS_CREATE_ONE_BEGIN';
export const CAUSE_SICKNESS_CREATE_ONE_SUCCESS = 'CAUSE_SICKNESS_CREATE_ONE_SUCCESS';
export const CAUSE_SICKNESS_CREATE_ONE_FAILURE = 'CAUSE_SICKNESS_CREATE_ONE_FAILURE';
export const CAUSE_SICKNESS_CREATE_ONE_DISMISS_ERROR = 'CAUSE_SICKNESS_CREATE_ONE_DISMISS_ERROR';
export const CAUSE_SICKNESS_UPDATE_ONE_BEGIN = 'CAUSE_SICKNESS_UPDATE_ONE_BEGIN';
export const CAUSE_SICKNESS_UPDATE_ONE_SUCCESS = 'CAUSE_SICKNESS_UPDATE_ONE_SUCCESS';
export const CAUSE_SICKNESS_UPDATE_ONE_FAILURE = 'CAUSE_SICKNESS_UPDATE_ONE_FAILURE';
export const CAUSE_SICKNESS_UPDATE_ONE_DISMISS_ERROR = 'CAUSE_SICKNESS_UPDATE_ONE_DISMISS_ERROR';
export const CAUSE_SICKNESS_DEL_ONE_BEGIN = 'CAUSE_SICKNESS_DEL_ONE_BEGIN';
export const CAUSE_SICKNESS_DEL_ONE_SUCCESS = 'CAUSE_SICKNESS_DEL_ONE_SUCCESS';
export const CAUSE_SICKNESS_DEL_ONE_FAILURE = 'CAUSE_SICKNESS_DEL_ONE_FAILURE';
export const CAUSE_SICKNESS_DEL_ONE_DISMISS_ERROR = 'CAUSE_SICKNESS_DEL_ONE_DISMISS_ERROR';
export const CAUSE_SICKNESS_LOAD_PENDINGS_BEGIN = 'CAUSE_SICKNESS_LOAD_PENDINGS_BEGIN';
export const CAUSE_SICKNESS_LOAD_PENDINGS_SUCCESS = 'CAUSE_SICKNESS_LOAD_PENDINGS_SUCCESS';
export const CAUSE_SICKNESS_LOAD_PENDINGS_FAILURE = 'CAUSE_SICKNESS_LOAD_PENDINGS_FAILURE';
export const CAUSE_SICKNESS_LOAD_PENDINGS_DISMISS_ERROR = 'CAUSE_SICKNESS_LOAD_PENDINGS_DISMISS_ERROR';
export const CAUSE_SICKNESS_UPDATE_ONE_UPDATE = 'CAUSE_SICKNESS_UPDATE_ONE_UPDATE';
