export const CAUSE_MOVEMENT_LOAD_ONE_BEGIN = 'CAUSE_MOVEMENT_LOAD_ONE_BEGIN';
export const CAUSE_MOVEMENT_LOAD_ONE_SUCCESS = 'CAUSE_MOVEMENT_LOAD_ONE_SUCCESS';
export const CAUSE_MOVEMENT_LOAD_ONE_FAILURE = 'CAUSE_MOVEMENT_LOAD_ONE_FAILURE';
export const CAUSE_MOVEMENT_LOAD_ONE_DISMISS_ERROR = 'CAUSE_MOVEMENT_LOAD_ONE_DISMISS_ERROR';
export const CAUSE_MOVEMENT_LOAD_MOVEMENTS_BEGIN = 'CAUSE_MOVEMENT_LOAD_MOVEMENTS_BEGIN';
export const CAUSE_MOVEMENT_LOAD_MOVEMENTS_SUCCESS = 'CAUSE_MOVEMENT_LOAD_MOVEMENTS_SUCCESS';
export const CAUSE_MOVEMENT_LOAD_MOVEMENTS_FAILURE = 'CAUSE_MOVEMENT_LOAD_MOVEMENTS_FAILURE';
export const CAUSE_MOVEMENT_LOAD_MOVEMENTS_DISMISS_ERROR = 'CAUSE_MOVEMENT_LOAD_MOVEMENTS_DISMISS_ERROR';
export const CAUSE_MOVEMENT_CREATE_ONE_BEGIN = 'CAUSE_MOVEMENT_CREATE_ONE_BEGIN';
export const CAUSE_MOVEMENT_CREATE_ONE_SUCCESS = 'CAUSE_MOVEMENT_CREATE_ONE_SUCCESS';
export const CAUSE_MOVEMENT_CREATE_ONE_FAILURE = 'CAUSE_MOVEMENT_CREATE_ONE_FAILURE';
export const CAUSE_MOVEMENT_CREATE_ONE_DISMISS_ERROR = 'CAUSE_MOVEMENT_CREATE_ONE_DISMISS_ERROR';
export const CAUSE_MOVEMENT_UPDATE_ONE_UPDATE = 'CAUSE_MOVEMENT_UPDATE_ONE_UPDATE';
export const CAUSE_MOVEMENT_UPDATE_MODEL = 'CAUSE_MOVEMENT_UPDATE_MODEL';
export const CAUSE_MOVEMENT_UPDATE_ONE_BEGIN = 'CAUSE_MOVEMENT_UPDATE_ONE_BEGIN';
export const CAUSE_MOVEMENT_UPDATE_ONE_SUCCESS = 'CAUSE_MOVEMENT_UPDATE_ONE_SUCCESS';
export const CAUSE_MOVEMENT_UPDATE_ONE_FAILURE = 'CAUSE_MOVEMENT_UPDATE_ONE_FAILURE';
export const CAUSE_MOVEMENT_UPDATE_ONE_DISMISS_ERROR = 'CAUSE_MOVEMENT_UPDATE_ONE_DISMISS_ERROR';
export const CAUSE_MOVEMENT_DEL_ONE_BEGIN = 'CAUSE_MOVEMENT_DEL_ONE_BEGIN';
export const CAUSE_MOVEMENT_DEL_ONE_SUCCESS = 'CAUSE_MOVEMENT_DEL_ONE_SUCCESS';
export const CAUSE_MOVEMENT_DEL_ONE_FAILURE = 'CAUSE_MOVEMENT_DEL_ONE_FAILURE';
export const CAUSE_MOVEMENT_DEL_ONE_DISMISS_ERROR = 'CAUSE_MOVEMENT_DEL_ONE_DISMISS_ERROR';
export const CAUSE_MOVEMENT_VALIDATE_ONE_BEGIN = 'CAUSE_MOVEMENT_VALIDATE_ONE_BEGIN';
export const CAUSE_MOVEMENT_VALIDATE_ONE_SUCCESS = 'CAUSE_MOVEMENT_VALIDATE_ONE_SUCCESS';
export const CAUSE_MOVEMENT_VALIDATE_ONE_FAILURE = 'CAUSE_MOVEMENT_VALIDATE_ONE_FAILURE';
export const CAUSE_MOVEMENT_VALIDATE_ONE_DISMISS_ERROR = 'CAUSE_MOVEMENT_VALIDATE_ONE_DISMISS_ERROR';
export const CAUSE_MOVEMENT_LOAD_PENDINGS_BEGIN = 'CAUSE_MOVEMENT_LOAD_PENDINGS_BEGIN';
export const CAUSE_MOVEMENT_LOAD_PENDINGS_SUCCESS = 'CAUSE_MOVEMENT_LOAD_PENDINGS_SUCCESS';
export const CAUSE_MOVEMENT_LOAD_PENDINGS_FAILURE = 'CAUSE_MOVEMENT_LOAD_PENDINGS_FAILURE';
export const CAUSE_MOVEMENT_LOAD_PENDINGS_DISMISS_ERROR = 'CAUSE_MOVEMENT_LOAD_PENDINGS_DISMISS_ERROR';
