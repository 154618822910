export const SITE_LOAD_MORE_INIT = 'SITE_LOAD_MORE_INIT';
export const SITE_LOAD_MORE_BEGIN = 'SITE_LOAD_MORE_BEGIN';
export const SITE_LOAD_MORE_SUCCESS = 'SITE_LOAD_MORE_SUCCESS';
export const SITE_LOAD_MORE_FAILURE = 'SITE_LOAD_MORE_FAILURE';
export const SITE_LOAD_MORE_DISMISS_ERROR = 'SITE_LOAD_MORE_DISMISS_ERROR';
export const SITE_LOAD_ONE_BEGIN = 'SITE_LOAD_ONE_BEGIN';
export const SITE_LOAD_ONE_SUCCESS = 'SITE_LOAD_ONE_SUCCESS';
export const SITE_LOAD_ONE_FAILURE = 'SITE_LOAD_ONE_FAILURE';
export const SITE_LOAD_ONE_DISMISS_ERROR = 'SITE_LOAD_ONE_DISMISS_ERROR';
export const SITE_CREATE_ONE_BEGIN = 'SITE_CREATE_ONE_BEGIN';
export const SITE_CREATE_ONE_SUCCESS = 'SITE_CREATE_ONE_SUCCESS';
export const SITE_CREATE_ONE_FAILURE = 'SITE_CREATE_ONE_FAILURE';
export const SITE_CREATE_ONE_DISMISS_ERROR = 'SITE_CREATE_ONE_DISMISS_ERROR';
export const SITE_UPDATE_ONE_BEGIN = 'SITE_UPDATE_ONE_BEGIN';
export const SITE_UPDATE_ONE_SUCCESS = 'SITE_UPDATE_ONE_SUCCESS';
export const SITE_UPDATE_ONE_FAILURE = 'SITE_UPDATE_ONE_FAILURE';
export const SITE_UPDATE_ONE_DISMISS_ERROR = 'SITE_UPDATE_ONE_DISMISS_ERROR';
export const SITE_UPDATE_ONE_UPDATE = 'SITE_UPDATE_ONE_UPDATE';
export const SITE_FILTER_BEGIN = 'SITE_FILTER_BEGIN';
export const SITE_FILTER_SUCCESS = 'SITE_FILTER_SUCCESS';
export const SITE_FILTER_FAILURE = 'SITE_FILTER_FAILURE';
export const SITE_FILTER_DISMISS_ERROR = 'SITE_FILTER_DISMISS_ERROR';
export const SITE_DEL_ONE_BEGIN = 'SITE_DEL_ONE_BEGIN';
export const SITE_DEL_ONE_SUCCESS = 'SITE_DEL_ONE_SUCCESS';
export const SITE_DEL_ONE_FAILURE = 'SITE_DEL_ONE_FAILURE';
export const SITE_DEL_ONE_DISMISS_ERROR = 'SITE_DEL_ONE_DISMISS_ERROR';
export const SITE_CLEAR_ITEMS = 'SITE_CLEAR_ITEMS';
export const SITE_INIT_FILTERS = 'SITE_INIT_FILTERS';
export const SITE_INIT_SORT = 'SITE_INIT_SORT';
export const SITE_SET_FILTERS = 'SITE_SET_FILTERS';
export const SITE_SET_SORT = 'SITE_SET_SORT';
export const SITE_UPDATE_QUICK_SEARCH = 'SITE_UPDATE_QUICK_SEARCH';
export const SITE_UPDATE_SORT = 'SITE_UPDATE_SORT';
export const SITE_LOAD_CAUSES_BEGIN = 'SITE_LOAD_CAUSES_BEGIN';
export const SITE_LOAD_CAUSES_SUCCESS = 'SITE_LOAD_CAUSES_SUCCESS';
export const SITE_LOAD_CAUSES_FAILURE = 'SITE_LOAD_CAUSES_FAILURE';
export const SITE_LOAD_CAUSES_DISMISS_ERROR = 'SITE_LOAD_CAUSES_DISMISS_ERROR';
export const SITE_LOAD_PHOTOS_BEGIN = 'SITE_LOAD_PHOTOS_BEGIN';
export const SITE_LOAD_PHOTOS_SUCCESS = 'SITE_LOAD_PHOTOS_SUCCESS';
export const SITE_LOAD_PHOTOS_FAILURE = 'SITE_LOAD_PHOTOS_FAILURE';
export const SITE_LOAD_PHOTOS_DISMISS_ERROR = 'SITE_LOAD_PHOTOS_DISMISS_ERROR';
export const SITE_UPLOAD_SITE_MEDIA_BEGIN = 'SITE_UPLOAD_SITE_MEDIA_BEGIN';
export const SITE_UPLOAD_SITE_MEDIA_SUCCESS = 'SITE_UPLOAD_SITE_MEDIA_SUCCESS';
export const SITE_UPLOAD_SITE_MEDIA_FAILURE = 'SITE_UPLOAD_SITE_MEDIA_FAILURE';
export const SITE_UPLOAD_SITE_MEDIA_DISMISS_ERROR = 'SITE_UPLOAD_SITE_MEDIA_DISMISS_ERROR';
export const SITE_DEL_SITE_MEDIA_BEGIN = 'SITE_DEL_SITE_MEDIA_BEGIN';
export const SITE_DEL_SITE_MEDIA_SUCCESS = 'SITE_DEL_SITE_MEDIA_SUCCESS';
export const SITE_DEL_SITE_MEDIA_FAILURE = 'SITE_DEL_SITE_MEDIA_FAILURE';
export const SITE_DEL_SITE_MEDIA_DISMISS_ERROR = 'SITE_DEL_SITE_MEDIA_DISMISS_ERROR';
export const SITE_LOAD_DOCUMENTS_BEGIN = 'SITE_LOAD_DOCUMENTS_BEGIN';
export const SITE_LOAD_DOCUMENTS_SUCCESS = 'SITE_LOAD_DOCUMENTS_SUCCESS';
export const SITE_LOAD_DOCUMENTS_FAILURE = 'SITE_LOAD_DOCUMENTS_FAILURE';
export const SITE_LOAD_DOCUMENTS_DISMISS_ERROR = 'SITE_LOAD_DOCUMENTS_DISMISS_ERROR';
export const SITE_UPDATE_SITE_MEDIA_DESC_BEGIN = 'SITE_UPDATE_SITE_MEDIA_DESC_BEGIN';
export const SITE_UPDATE_SITE_MEDIA_DESC_SUCCESS = 'SITE_UPDATE_SITE_MEDIA_DESC_SUCCESS';
export const SITE_UPDATE_SITE_MEDIA_DESC_FAILURE = 'SITE_UPDATE_SITE_MEDIA_DESC_FAILURE';
export const SITE_UPDATE_SITE_MEDIA_DESC_DISMISS_ERROR = 'SITE_UPDATE_SITE_MEDIA_DESC_DISMISS_ERROR';
export const SITE_ON_SELECT = 'SITE_ON_SELECT';
export const SITE_SELECT_ALL = 'SITE_SELECT_ALL';
export const SITE_SELECT_NONE = 'SITE_SELECT_NONE';
export const SITE_PRINT_ONE_BEGIN = 'SITE_PRINT_ONE_BEGIN';
export const SITE_PRINT_ONE_SUCCESS = 'SITE_PRINT_ONE_SUCCESS';
export const SITE_PRINT_ONE_FAILURE = 'SITE_PRINT_ONE_FAILURE';
export const SITE_PRINT_ONE_DISMISS_ERROR = 'SITE_PRINT_ONE_DISMISS_ERROR';
export const SITE_EXPORT_AS_TAB_BEGIN = 'SITE_EXPORT_AS_TAB_BEGIN';
export const SITE_EXPORT_AS_TAB_SUCCESS = 'SITE_EXPORT_AS_TAB_SUCCESS';
export const SITE_EXPORT_AS_TAB_FAILURE = 'SITE_EXPORT_AS_TAB_FAILURE';
export const SITE_EXPORT_AS_TAB_DISMISS_ERROR = 'SITE_EXPORT_AS_TAB_DISMISS_ERROR';
