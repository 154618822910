export const EMAIL_LOAD_MORE_INIT = 'EMAIL_LOAD_MORE_INIT';
export const EMAIL_LOAD_MORE_BEGIN = 'EMAIL_LOAD_MORE_BEGIN';
export const EMAIL_LOAD_MORE_SUCCESS = 'EMAIL_LOAD_MORE_SUCCESS';
export const EMAIL_LOAD_MORE_FAILURE = 'EMAIL_LOAD_MORE_FAILURE';
export const EMAIL_LOAD_MORE_DISMISS_ERROR = 'EMAIL_LOAD_MORE_DISMISS_ERROR';
export const EMAIL_CLEAR_ITEMS = 'EMAIL_CLEAR_ITEMS';
export const EMAIL_LOAD_ONE_BEGIN = 'EMAIL_LOAD_ONE_BEGIN';
export const EMAIL_LOAD_ONE_SUCCESS = 'EMAIL_LOAD_ONE_SUCCESS';
export const EMAIL_LOAD_ONE_FAILURE = 'EMAIL_LOAD_ONE_FAILURE';
export const EMAIL_LOAD_ONE_DISMISS_ERROR = 'EMAIL_LOAD_ONE_DISMISS_ERROR';
export const EMAIL_UPDATE_ONE_BEGIN = 'EMAIL_UPDATE_ONE_BEGIN';
export const EMAIL_UPDATE_ONE_SUCCESS = 'EMAIL_UPDATE_ONE_SUCCESS';
export const EMAIL_UPDATE_ONE_FAILURE = 'EMAIL_UPDATE_ONE_FAILURE';
export const EMAIL_UPDATE_ONE_DISMISS_ERROR = 'EMAIL_UPDATE_ONE_DISMISS_ERROR';
export const EMAIL_UPDATE_ONE_UPDATE = 'EMAIL_UPDATE_ONE_UPDATE'
export const EMAIL_CREATE_ONE_BEGIN = 'EMAIL_CREATE_ONE_BEGIN';
export const EMAIL_CREATE_ONE_SUCCESS = 'EMAIL_CREATE_ONE_SUCCESS';
export const EMAIL_CREATE_ONE_FAILURE = 'EMAIL_CREATE_ONE_FAILURE';
export const EMAIL_CREATE_ONE_DISMISS_ERROR = 'EMAIL_CREATE_ONE_DISMISS_ERROR';
export const EMAIL_DEL_ONE_BEGIN = 'EMAIL_DEL_ONE_BEGIN';
export const EMAIL_DEL_ONE_SUCCESS = 'EMAIL_DEL_ONE_SUCCESS';
export const EMAIL_DEL_ONE_FAILURE = 'EMAIL_DEL_ONE_FAILURE';
export const EMAIL_DEL_ONE_DISMISS_ERROR = 'EMAIL_DEL_ONE_DISMISS_ERROR';
