export const ITEM_LOAD_MORE_INIT = 'ITEM_LOAD_MORE_INIT';
export const ITEM_LOAD_MORE_BEGIN = 'ITEM_LOAD_MORE_BEGIN';
export const ITEM_LOAD_MORE_SUCCESS = 'ITEM_LOAD_MORE_SUCCESS';
export const ITEM_LOAD_MORE_FAILURE = 'ITEM_LOAD_MORE_FAILURE';
export const ITEM_LOAD_MORE_DISMISS_ERROR = 'ITEM_LOAD_MORE_DISMISS_ERROR';
export const ITEM_LOAD_ONE_BEGIN = 'ITEM_LOAD_ONE_BEGIN';
export const ITEM_LOAD_ONE_SUCCESS = 'ITEM_LOAD_ONE_SUCCESS';
export const ITEM_LOAD_ONE_FAILURE = 'ITEM_LOAD_ONE_FAILURE';
export const ITEM_LOAD_ONE_DISMISS_ERROR = 'ITEM_LOAD_ONE_DISMISS_ERROR';
export const ITEM_UPDATE_ONE_BEGIN = 'ITEM_UPDATE_ONE_BEGIN';
export const ITEM_UPDATE_ONE_SUCCESS = 'ITEM_UPDATE_ONE_SUCCESS';
export const ITEM_UPDATE_ONE_FAILURE = 'ITEM_UPDATE_ONE_FAILURE';
export const ITEM_UPDATE_ONE_DISMISS_ERROR = 'ITEM_UPDATE_ONE_DISMISS_ERROR';
export const ITEM_UPDATE_ONE_UPDATE = 'ITEM_UPDATE_ONE_UPDATE';
export const ITEM_CREATE_ONE_BEGIN = 'ITEM_CREATE_ONE_BEGIN';
export const ITEM_CREATE_ONE_SUCCESS = 'ITEM_CREATE_ONE_SUCCESS';
export const ITEM_CREATE_ONE_FAILURE = 'ITEM_CREATE_ONE_FAILURE';
export const ITEM_CREATE_ONE_DISMISS_ERROR = 'ITEM_CREATE_ONE_DISMISS_ERROR';
export const ITEM_DEL_ONE_BEGIN = 'ITEM_DEL_ONE_BEGIN';
export const ITEM_DEL_ONE_SUCCESS = 'ITEM_DEL_ONE_SUCCESS';
export const ITEM_DEL_ONE_FAILURE = 'ITEM_DEL_ONE_FAILURE';
export const ITEM_DEL_ONE_DISMISS_ERROR = 'ITEM_DEL_ONE_DISMISS_ERROR';
