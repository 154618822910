export const CLIENT_LOAD_MORE_INIT = 'CLIENT_LOAD_MORE_INIT';
export const CLIENT_LOAD_MORE_BEGIN = 'CLIENT_LOAD_MORE_BEGIN';
export const CLIENT_LOAD_MORE_SUCCESS = 'CLIENT_LOAD_MORE_SUCCESS';
export const CLIENT_LOAD_MORE_FAILURE = 'CLIENT_LOAD_MORE_FAILURE';
export const CLIENT_LOAD_MORE_DISMISS_ERROR = 'CLIENT_LOAD_MORE_DISMISS_ERROR';
export const CLIENT_LOAD_ONE_BEGIN = 'CLIENT_LOAD_ONE_BEGIN';
export const CLIENT_LOAD_ONE_SUCCESS = 'CLIENT_LOAD_ONE_SUCCESS';
export const CLIENT_LOAD_ONE_FAILURE = 'CLIENT_LOAD_ONE_FAILURE';
export const CLIENT_LOAD_ONE_DISMISS_ERROR = 'CLIENT_LOAD_ONE_DISMISS_ERROR';
export const CLIENT_UPDATE_ONE_BEGIN = 'CLIENT_UPDATE_ONE_BEGIN';
export const CLIENT_UPDATE_ONE_SUCCESS = 'CLIENT_UPDATE_ONE_SUCCESS';
export const CLIENT_UPDATE_ONE_FAILURE = 'CLIENT_UPDATE_ONE_FAILURE';
export const CLIENT_UPDATE_ONE_DISMISS_ERROR = 'CLIENT_UPDATE_ONE_DISMISS_ERROR';
export const CLIENT_UPDATE_ONE_UPDATE = 'CLIENT_UPDATE_ONE_UPDATE';
export const CLIENT_DEL_ONE_BEGIN = 'CLIENT_DEL_ONE_BEGIN';
export const CLIENT_DEL_ONE_SUCCESS = 'CLIENT_DEL_ONE_SUCCESS';
export const CLIENT_DEL_ONE_FAILURE = 'CLIENT_DEL_ONE_FAILURE';
export const CLIENT_DEL_ONE_DISMISS_ERROR = 'CLIENT_DEL_ONE_DISMISS_ERROR';
export const CLIENT_CREATE_ONE_BEGIN = 'CLIENT_CREATE_ONE_BEGIN';
export const CLIENT_CREATE_ONE_SUCCESS = 'CLIENT_CREATE_ONE_SUCCESS';
export const CLIENT_CREATE_ONE_FAILURE = 'CLIENT_CREATE_ONE_FAILURE';
export const CLIENT_CREATE_ONE_DISMISS_ERROR = 'CLIENT_CREATE_ONE_DISMISS_ERROR';
export const CLIENT_CLEAR_ITEMS = 'CLIENT_CLEAR_ITEMS';
export const CLIENT_INIT_FILTERS = 'CLIENT_INIT_FILTERS';
export const CLIENT_INIT_SORT = 'CLIENT_INIT_SORT';
export const CLIENT_SET_FILTERS = 'CLIENT_SET_FILTERS';
export const CLIENT_SET_SORT = 'CLIENT_SET_SORT';
export const CLIENT_UPDATE_QUICK_SEARCH = 'CLIENT_UPDATE_QUICK_SEARCH';
export const CLIENT_UPDATE_SORT = 'CLIENT_UPDATE_SORT';
export const CLIENT_LOAD_CLI_SPEC_BEGIN = 'CLIENT_LOAD_CLI_SPEC_BEGIN';
export const CLIENT_LOAD_CLI_SPEC_SUCCESS = 'CLIENT_LOAD_CLI_SPEC_SUCCESS';
export const CLIENT_LOAD_CLI_SPEC_FAILURE = 'CLIENT_LOAD_CLI_SPEC_FAILURE';
export const CLIENT_LOAD_CLI_SPEC_DISMISS_ERROR = 'CLIENT_LOAD_CLI_SPEC_DISMISS_ERROR';
export const CLIENT_ON_SELECT = 'CLIENT_ON_SELECT';
export const CLIENT_SELECT_ALL = 'CLIENT_SELECT_ALL';
export const CLIENT_SELECT_NONE = 'CLIENT_SELECT_NONE';
export const CLIENT_EXPORT_AS_TAB_BEGIN = 'CLIENT_EXPORT_AS_TAB_BEGIN';
export const CLIENT_EXPORT_AS_TAB_SUCCESS = 'CLIENT_EXPORT_AS_TAB_SUCCESS';
export const CLIENT_EXPORT_AS_TAB_FAILURE = 'CLIENT_EXPORT_AS_TAB_FAILURE';
export const CLIENT_EXPORT_AS_TAB_DISMISS_ERROR = 'CLIENT_EXPORT_AS_TAB_DISMISS_ERROR';
