export const MOVEMENT_LOAD_MORE_INIT = 'MOVEMENT_LOAD_MORE_INIT';
export const MOVEMENT_LOAD_MORE_BEGIN = 'MOVEMENT_LOAD_MORE_BEGIN';
export const MOVEMENT_LOAD_MORE_SUCCESS = 'MOVEMENT_LOAD_MORE_SUCCESS';
export const MOVEMENT_LOAD_MORE_FAILURE = 'MOVEMENT_LOAD_MORE_FAILURE';
export const MOVEMENT_LOAD_MORE_DISMISS_ERROR = 'MOVEMENT_LOAD_MORE_DISMISS_ERROR';
export const MOVEMENT_LOAD_ONE_BEGIN = 'MOVEMENT_LOAD_ONE_BEGIN';
export const MOVEMENT_LOAD_ONE_SUCCESS = 'MOVEMENT_LOAD_ONE_SUCCESS';
export const MOVEMENT_LOAD_ONE_FAILURE = 'MOVEMENT_LOAD_ONE_FAILURE';
export const MOVEMENT_LOAD_ONE_DISMISS_ERROR = 'MOVEMENT_LOAD_ONE_DISMISS_ERROR';
export const MOVEMENT_CREATE_ONE_BEGIN = 'MOVEMENT_CREATE_ONE_BEGIN';
export const MOVEMENT_CREATE_ONE_SUCCESS = 'MOVEMENT_CREATE_ONE_SUCCESS';
export const MOVEMENT_CREATE_ONE_FAILURE = 'MOVEMENT_CREATE_ONE_FAILURE';
export const MOVEMENT_CREATE_ONE_DISMISS_ERROR = 'MOVEMENT_CREATE_ONE_DISMISS_ERROR';
export const MOVEMENT_UPDATE_ONE_BEGIN = 'MOVEMENT_UPDATE_ONE_BEGIN';
export const MOVEMENT_UPDATE_ONE_SUCCESS = 'MOVEMENT_UPDATE_ONE_SUCCESS';
export const MOVEMENT_UPDATE_ONE_FAILURE = 'MOVEMENT_UPDATE_ONE_FAILURE';
export const MOVEMENT_UPDATE_ONE_DISMISS_ERROR = 'MOVEMENT_UPDATE_ONE_DISMISS_ERROR';
export const MOVEMENT_CLEAR_ITEMS = 'MOVEMENT_CLEAR_ITEMS';
export const MOVEMENT_UPDATE_ONE_UPDATE = 'MOVEMENT_UPDATE_ONE_UPDATE';
export const MOVEMENT_DEL_ONE_BEGIN = 'MOVEMENT_DEL_ONE_BEGIN';
export const MOVEMENT_DEL_ONE_SUCCESS = 'MOVEMENT_DEL_ONE_SUCCESS';
export const MOVEMENT_DEL_ONE_FAILURE = 'MOVEMENT_DEL_ONE_FAILURE';
export const MOVEMENT_DEL_ONE_DISMISS_ERROR = 'MOVEMENT_DEL_ONE_DISMISS_ERROR';
export const MOVEMENT_FILTER_BEGIN = 'MOVEMENT_FILTER_BEGIN';
export const MOVEMENT_FILTER_SUCCESS = 'MOVEMENT_FILTER_SUCCESS';
export const MOVEMENT_FILTER_FAILURE = 'MOVEMENT_FILTER_FAILURE';
export const MOVEMENT_FILTER_DISMISS_ERROR = 'MOVEMENT_FILTER_DISMISS_ERROR';
export const MOVEMENT_INIT_FILTERS = 'MOVEMENT_INIT_FILTERS';
export const MOVEMENT_INIT_SORT = 'MOVEMENT_INIT_SORT';
export const MOVEMENT_SET_FILTERS = 'MOVEMENT_SET_FILTERS';
export const MOVEMENT_SET_SORT = 'MOVEMENT_SET_SORT';
export const MOVEMENT_UPDATE_QUICK_SEARCH = 'MOVEMENT_UPDATE_QUICK_SEARCH';
export const MOVEMENT_UPDATE_SORT = 'MOVEMENT_UPDATE_SORT';
export const MOVEMENT_LOAD_PENDINGS_BEGIN = 'MOVEMENT_LOAD_PENDINGS_BEGIN';
export const MOVEMENT_LOAD_PENDINGS_SUCCESS = 'MOVEMENT_LOAD_PENDINGS_SUCCESS';
export const MOVEMENT_LOAD_PENDINGS_FAILURE = 'MOVEMENT_LOAD_PENDINGS_FAILURE';
export const MOVEMENT_LOAD_PENDINGS_DISMISS_ERROR = 'MOVEMENT_LOAD_PENDINGS_DISMISS_ERROR';
export const MOVEMENT_VALIDATE_ONE_BEGIN = 'MOVEMENT_VALIDATE_ONE_BEGIN';
export const MOVEMENT_VALIDATE_ONE_SUCCESS = 'MOVEMENT_VALIDATE_ONE_SUCCESS';
export const MOVEMENT_VALIDATE_ONE_FAILURE = 'MOVEMENT_VALIDATE_ONE_FAILURE';
export const MOVEMENT_VALIDATE_ONE_DISMISS_ERROR = 'MOVEMENT_VALIDATE_ONE_DISMISS_ERROR';
